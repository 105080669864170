<template>
  <div>
    <div v-if="!!callItem && activityCounterparts && activityCounterparts.length > 0"
         class="mt-3"
    >
      <div class="d-flex">
        <div class="row col-3 mt-2">
          {{ staticText.updateTask }}
        </div>

        <div class="w-100">
          <b-form-input v-model="subject"
                        :placeholder="callItem.name"
                        class="d-flex ml-auto mb-3"
                        type="text"
          ></b-form-input>
        </div>
      </div>

      <!-- assign task to available user -->
      <div v-if="Array.isArray(users) && users.length"
           class="d-flex mb-2"
      >
        <div class="row col-3 mt-2">
          {{ staticText.assignTaskTo }}
        </div>

        <multiselect v-model="selectedUser"
                     :options="users"
                     :show-labels="false"
                     track-by="id"
                     label="name"
        ></multiselect>
      </div>

      <div class="d-flex mb-2">
        <div class="w-50 mr-2">
          <VueCtkDateTimePicker id="crm-date-picker-202101271355"
                                v-model="dateTime"
                                :label="this.timeAllowed ? staticText.datetimeLabel : staticText.dateLabel"
                                :error="dateError"
                                :format="datetimeFormat"
                                :formatted="datetimeFormatted"
                                :onlyDate="!this.timeAllowed"
          />
        </div>

        <BaseCounterpartSelector
          v-if="activityCounterparts"
          :pre-selected-counterparts="activityCounterparts[0] || null"
          :items="activityCounterparts"
          @input="value => selectedCounterpart=value"
        />
      </div>

      <!--  Additional Fields    -->
      <div v-for="field in correspondingFields"
           :key="field.id"
      >
        <div class="d-flex row my-2 align-items-center">
          <div class="col-4"> {{ field.label }}:</div>

          <typed-input-field v-model="crmItems[field.raw_definition.id]"
                             :itemId="callItem.uniqueId"
                             :datetimeFormatString="getDatetimeFormat(field)"
                             :linked-field="field"
                             :field-definition="field.raw_definition"
                             class="col"
          ></typed-input-field>

        </div>
      </div>

      <!-- create button -->
      <div class="w-100 d-flex justify-content-end">
        <ActivityCreateButton
          :is-created="created"
          :is-creating="creating"
          :button-label="staticText.createTaskBtn"
          :creating-label="staticText.creatingTask"
          :created-label="staticText.createdTask"
          :disabled="isPreviewMode"
          @create="createTask"
        />
      </div>
    </div>

    <div v-else>
      <base-alert
        id="no-counterpart-alert-202101051246"
        :alert-message="staticText.alertText"
        :show-alert="true"
        variant="danger"
        class="mt-3"
      ></base-alert>
    </div>
  </div>
</template>

<script>
import { ActivityCreatorMixin } from "@/apps/call/CallViewItem_components/ActivityCreatorMixin"

export default {
  name: "TaskCreator",
  mixins: [ActivityCreatorMixin],
  props: {
    callItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        createTaskBtn: "Create CRM Task",
        datetimeLabel: "Select date & time",
        dateLabel: "Select date",
        noDateError: "Date invalid",
        createdTask: "Task created",
        creatingTask: "Creating task ...",
        updateTask: "Task Subject",
        assignTaskTo: "Assign task to",
        alertText: "To create a CRM task select a counterpart in the top-right corner.",
        taskDueDateLabel: "Task Due Date",
        assignedToLabel: "Assigned to",
        counterpartLabel: "Counterpart",
        linkToActivityLabel: "Link to Task",
        warningMessage: "Error in creating CRM Task."
      },
      selectedCounterpart: null,
      dateError: false,
      dateTime: null,
      crmItems: {},
      taskCreateFailed: false
    }
  },
  computed: {
    timeAllowed () {
      const timeAllowedServices = ["hubspot", "pipedrive", "zendesk", "sugarcrm"]
      return this.selectedCounterpart && timeAllowedServices.includes(this.selectedCounterpart.crm_service)
    },
    datetimeFormat () {
      if (this.timeAllowed) return "YYYY-MM-DD HH:mm"
      return "YYYY-MM-DD"
    },
    datetimeFormatted () {
      if (this.timeAllowed) return "lll"
      return "ll"
    },
    correspondingFields () {
      if (!this.callItem.crm_items) return []
      return this.callItem.crm_items.filter(field => {
        return this.selectedCounterpart &&
          this.selectedCounterpart.crm_service &&
          field.crm_object_link.service_key === this.selectedCounterpart.crm_service
      })
    }
  },
  watch: {
    activityCounterparts: {
      handler (val) {
        if (!this.selectedCounterpart) {
          this.selectedCounterpart = val[0]
          if (this.selectedCounterpart) this.getActivityUsers(this.selectedCounterpart.crm_service)
        }
      },
      deep: true
    },
    counterpart (val) {
      if (val) this.getActivityUsers(val.crm_service)
    },
    dateTime () {
      this.dateError = false
    },
    callItem: {
      handler (newValue) {
        this.itemNotes = newValue.notes
      },
      deep: true
    }
  },
  mounted () {
    this.itemNotes = this.callItem.notes
    this.subject = this.callItem.name
    if (!this.selectedCounterpart && !!this.activityCounterparts && this.activityCounterparts.length) {
      this.selectedCounterpart = this.activityCounterparts[0]
    }
    if (this.selectedCounterpart) {
      this.getActivityUsers(this.selectedCounterpart.crm_service)
    }
  },
  methods: {
    createTask () {
      if (!this.dateTime) {
        this.dateError = true
        return
      }
      const data = {
        subject: this.subject,
        date: this.dateTime
      }
      data.text = this.itemNotes ? this.utils.strip(this.itemNotes) : ""
      if (this.selectedUser) {
        data.task_assignee_id = this.selectedUser.id
      }
      if (this.crmItems && Object.keys(this.crmItems).length > 0) {
        data.crm_items = {}
        for (const [fieldName, fieldValue] of Object.entries(this.crmItems)) {
          if (fieldValue != null) {
            data.crm_items[fieldName] = fieldValue.label ? fieldValue.label : fieldValue
          }
        }
      }
      this.createActivity("task", data)
    },
    getNotesToUpdate () {
      let notes = ""
      notes += this.selectedUser ? `<p>${this.staticText.assignedToLabel}: ${this.selectedUser.name}</p>` : ""
      notes += `<p>${this.staticText.taskDueDateLabel}: ${this.dateTime}</p>`
      notes += `<p>${this.staticText.counterpartLabel}: ${this.selectedCounterpart.name}</p>`

      for (const field of this.correspondingFields) {
        if (this.crmItems[field.crm_field_id]) {
          const fieldValue = this.crmItems[field.crm_field_id].label
            ? this.crmItems[field.crm_field_id].label
            : this.crmItems[field.crm_field_id]
          notes += `<p>${field.name}: ${fieldValue}</p>`
        }
      }
      return notes
    }
  }
}
</script>

<style scoped lang="scss">

</style>
